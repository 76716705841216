<template>
  <div class="member-page">
    <AdvancedQueryLayout
      class="advance-container ws_mb20"
      ref="advancedRef"
      :model="formInline"
      @fetch-list="fetchList"
    >
      <el-form-item label="帐号" prop="username">
        <el-input
          v-model="formInline.username"
          clearable
          maxlength="50"
          class="ws_select"
        />
      </el-form-item>
      <el-form-item label="用户等级" prop="vipCode">
        <el-select v-model="formInline.vipCode" class="ws_select" clearable>
          <el-option
            v-for="item in ops"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </AdvancedQueryLayout>
    <div class="ws_table">
      <el-table
        class="table ws_common_table"
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column
          v-for="(item, index) in col"
          :key="index"
          v-bind="{
            ...item,
          }"
          show-overflow-tooltip
        />
        <el-table-column label="操作" width="200" #default="scope">
          <OperationButtonList
            :buttonList="buttonList"
            :scope="scope.row"
          ></OperationButtonList>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        class="ws_mt50"
        v-model:current-page="pageParams.curPage"
        v-model:page-size="pageParams.pageSize"
        :page-sizes="PAGE_SIZES"
        layout="->, total, sizes, prev, pager, next, jumper "
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <ProductForm />
</template>
<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import OperationButtonList from '@/components/operation-button-list'
import { useRouter } from 'vue-router'
import { queryMemberPage } from '@/api'
import { CODE_ENUM, PAGE_SIZES, USER_LEVEL_TYPE } from '@/common'
import moment from 'moment'
import AdvancedQueryLayout from '@/components/advanced-query-layout'

const ops = [
  {
    label: '会员',
    value: USER_LEVEL_TYPE.USER_LEVEL_VIP,
  },
  {
    label: '普通',
    value: USER_LEVEL_TYPE.USER_LEVEL_ORDINARY,
  },
]

const router = useRouter()

const toDetail = ({ userId, mobile, vipCode }) => {
  router.push({
    name: 'person-detail',
    query: {
      userId,
      mobile,
      vipCode,
    },
  })
}

const buttonList = [
  {
    text: '详情',
    onClick: toDetail,
  },
]

const advancedRef = ref()

const tableData = ref([{}])
const formInline = ref({
  username: '',
  vipCode: '',
})

const pageParams = ref({
  curPage: 1,
  pageSize: 10,
})
const total = ref(0)

const handleSizeChange = () => {
  pageParams.value.curPage = 1
  fetchList()
}
const handleCurrentChange = () => {
  fetchList()
}

const col = [
  {
    prop: 'mobile',
    label: '用户账号',
  },
  {
    prop: 'vipName',
    label: '用户等级',
  },
  {
    prop: 'deviceCount',
    label: '设备数量',
  },
  {
    prop: 'createTime',
    label: '注册时间',
    formatter: ({ createTime }) => {
      return moment(createTime).format('YYYY-MM-DD HH:mm:ss')
    },
  },
]

const tableLoading = ref(false)
const fetchList = async (queryData) => {
  const keepAliveData = advancedRef.value.getKeepAliveData()
  const { data, reset } = queryData || {}

  if (typeof reset === 'boolean') {
    pageParams.value.curPage = 1
  }
  tableLoading.value = true
  const res = await queryMemberPage({
    ...pageParams.value,
    ...data,
    ...keepAliveData,
  }).finally(() => {
    tableLoading.value = false
  })
  if (res?.code === CODE_ENUM.SUCCESS) {
    tableData.value = res?.data?.records || []
    total.value = res?.data?.total || 0
  }
}

onMounted(async () => {
  fetchList()
})
</script>
<style lang="scss" scoped src="./index.scss"></style>
